import React, { useEffect, useState } from 'react';
import './dashboard.css';
import Sidebar from '../../components/SideBar';
import Logo from '../../assets/img/logoAzul.png'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faPlus, faChartSimple, faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../auth/firebaseConnection';
import { toast } from 'react-toastify';
import GraficoDemandas from '../../components/Graficos/graficoDemandas'
import GraficoContas from '../../components/Graficos/graficoContas';



function Dashboard() {
  const [agendamentos, setAgendamentos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Verifica se os agendamentos já estão em cache
        if (agendamentos.length === 0) {
          const agendamentosRef = collection(db, 'agendamentos');
          const q = query(agendamentosRef, where('data', '==', new Date().toLocaleDateString()));

          const querySnapshot = await getDocs(q);
          const data = querySnapshot.docs.map((doc) => doc.data());

          // Verifica se os dados são diferentes antes de atualizar o estado
          if (!arraysAreEqual(agendamentos, data)) {
            setAgendamentos(data);
          }
        }

      } catch (error) {
        toast.error('Erro ao buscar Agendamento');
      }
    };

    fetchData();
  }, [agendamentos]);

  // Função para comparar arrays
  const arraysAreEqual = (arr1, arr2) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  };

  return (
    <>
      <Sidebar />
      <div className='container container-dash'>
        <div className="dash">
          <Link to="/atendimento" className="card" style={{ background: '#647fff' }}>
            <FontAwesomeIcon icon={faPlus} className="icon-card" />
            <span className="description">Novo Atendimento</span>
          </Link>
          <Link to="/agendamento" className="card" style={{ background: '#4deacb' }}>
            <FontAwesomeIcon icon={faBook} className="icon-card" />
            <span className="description">Agendamento</span>
          </Link>
          <Link to="/demandas" className="card" style={{ background: '#06109a' }}>
            <FontAwesomeIcon icon={faChartSimple} className="icon-card" />
            <span className="description">Demandas</span>
          </Link>
          <Link to="/contas" className="card" style={{ background: '#fe4900' }}>
            <FontAwesomeIcon icon={faHandHoldingDollar} className="icon-card" />
            <span className="description">Contas</span>
          </Link>
        </div>

        <div className="gradient-bar">
          <input type="text" className="search-bar" placeholder="Buscar" />
        </div>

        <table className="table">
          <thead>
            <tr>
              <th className='username'>Agendamentos do Dia</th>
            </tr>
          </thead>
          <tbody>
            {agendamentos.map((agendamento, index) => (
              <tr key={index}>
                <td>{agendamento.usuario}</td>
                <td>{`${agendamento.hora1} - ${agendamento.hora2}`}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='graficos'>
          <div>
            <h3>Contas do mês</h3>
            <GraficoContas />
          </div>
          <div>
            <h3>Status das demandas</h3>
            <GraficoDemandas />
          </div>
        </div>

        <img className='logo-dash' src={Logo} alt='Logo Positive' />
      </div>
    </>
  );
}

export default Dashboard;
