import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../auth/firebaseConnection";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./cliente.css";
import Sidebar from "../../components/SideBar";

const ClientDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const client = location.state?.client;

  const [editableClient, setEditableClient] = useState(client);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableClient((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    try {
      const clientDoc = doc(db, "clientes", client.id);
      await updateDoc(clientDoc, editableClient);
      toast.success("Cliente atualizado com sucesso!");
      navigate("/cliente");
    } catch (error) {
      console.error("Erro ao atualizar cliente:", error);
      toast.error("Erro ao atualizar cliente.");
    }
  };

  if (!client) {
    return <p>Cliente não encontrado!</p>;
  }

  return (
    <>
      <Sidebar />
      <div className="container">
        <div className="content">
          <div className="details-container">
            <div className="header">
              <h1>Detalhes do Cliente</h1>
            </div>
            <form className="details-form">
              <div className="form-grid">
                {/* Nome */}
                <div className="form-group">
                  <label>Nome do Cliente:</label>
                  <input
                    type="text"
                    name="nome"
                    value={editableClient.nome || ""}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Telefone */}
                <div className="form-group">
                  <label>Telefone:</label>
                  <input
                    type="text"
                    name="telefone"
                    value={editableClient.telefone || ""}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Email */}
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={editableClient.email || ""}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Nome da Empresa */}
                <div className="form-group">
                  <label>Nome da Empresa:</label>
                  <input
                    type="text"
                    name="nomeEmpresa"
                    value={editableClient.nomeEmpresa || ""}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Cargo/Função */}
                <div className="form-group">
                  <label>Cargo/Função:</label>
                  <input
                    type="text"
                    name="cargoFuncao"
                    value={editableClient.cargoFuncao || ""}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Cidade */}
                <div className="form-group">
                  <label>Cidade:</label>
                  <input
                    type="text"
                    name="cidade"
                    value={editableClient.cidade || ""}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Estado */}
                <div className="form-group">
                  <label>Estado:</label>
                  <input
                    type="text"
                    name="estado"
                    value={editableClient.estado || ""}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Interesse */}
                <div className="form-group">
                  <label>Interesse:</label>
                  <select
                    name="interesse"
                    value={editableClient.interesse || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecione</option>
                    <option value="Consultoria">Consultoria</option>
                    <option value="Imersão">Imersão</option>
                    <option value="Mentoria Individual">Mentoria Individual</option>
                    <option value=">Mentoria em Grupo">Mentoria em Grupo</option>
                    <option value="Plataforma">Plataforma</option>
                    <option value="Treinamento">Treinamento</option>
                    <option value="Desenolvimento Web">Desenolvimento Web</option>
                  </select>
                </div>

                {/* Canal de Comunicação */}
                <div className="form-group">
                  <label>Canal de Comunicação:</label>
                  <select
                    name="canais"
                    value={editableClient.canais || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecione</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Whatsapp">WhatsApp</option>
                    <option value="Email">Email</option>
                    <option value="Indicacao">Indicação</option>
                  </select>
                </div>

                {/* Status */}
                <div className="form-group">
                  <label>Status:</label>
                  <select
                    name="status"
                    value={editableClient.status || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecione</option>
                    <option value="Novo Cliente">Novo Cliente</option>
                    <option value="Em Desenvolvimento">Em Desenvolvimento</option>
                    <option value="Finalizado">Finalizado</option>
                    <option value="Pagamento Pendente">Pagamento Pendente</option>
                  </select>
                </div>

                {/* Classificação */}
                <div className="form-group">
                  <label>Classificação:</label>
                  <select
                    name="qualificacao"
                    value={editableClient.qualificacao || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecione</option>
                    <option value="Frio">Frio</option>
                    <option value="Morno">Morno</option>
                    <option value="Quente">Quente</option>
                  </select>
                </div>

                {/* Produto */}
                <div className="form-group">
                  <label>Produto Adquirido:</label>
                  <input
                    type="text"
                    name="produto"
                    value={editableClient.produto || ""}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Primeiro Atendimento */}
                <div className="form-group">
                  <label>Primeiro Atendimento:</label>
                  <input
                    type="date"
                    name="primeiroAtedimento"
                    value={
                      editableClient.primeiroAtedimento &&
                        editableClient.primeiroAtedimento.seconds
                        ? new Date(
                          editableClient.primeiroAtedimento.seconds * 1000
                        )
                          .toISOString()
                          .split("T")[0]
                        : ""
                    }
                    readOnly
                  />
                </div>

                {/* Data da interação */}
                <div className="form-group">
                  <label>Data Ultima Interação:</label>
                  <input
                    type="date"
                    name="dataInteracao"
                    value={
                      editableClient.dataInteracao || ""
                    }
                    onChange={handleInputChange}
                  />
                </div>

                {/* Data da Proposta */}
                <div className="form-group">
                  <label>Data da Proposta:</label>
                  <input
                    type="date"
                    name="dataProposta"
                    value={
                      editableClient.dataProposta || ""
                    }
                    onChange={handleInputChange}
                  />
                </div>

                {/* Data de Fechamento */}
                <div className="form-group">
                  <label>Data de Fechamento:</label>
                  <input
                    type="date"
                    name="dataFechamento"
                    value={
                      editableClient.dataFechamento || ""
                    }
                    onChange={handleInputChange}
                  />
                </div>
                {/* Valor da Proposta */}
                <div className="form-group">
                  <label>Valor da Proposta:</label>
                  <input
                    type="text"
                    name="valorProposta"
                    value={editableClient.valorProposta || ""}
                    onChange={handleInputChange}
                  />
                </div>
                {/* Responsável */}
                <div className="form-group">
                  <label>Responsável:</label>
                  <input
                    type="text"
                    name="responsavel"
                    value={editableClient.responsavel || ""}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Observações */}
                <div className="form-group full-width">
                  <label>Observações:</label>
                  <textarea
                    name="observacao"
                    value={editableClient.observacao || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <button type="button" className="save-button" onClick={handleUpdate}>
                Atualizar Dados
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientDetails;